<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div class="host-body" v-else>
        <big-header></big-header>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";
import BigHeader from "../../layout/header.vue";
export default {
  mixins: [drawMixin],
  components: {
    BigHeader,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.cancelLoading();
  },
  methods: {
    cancelLoading() {
      // setTimeout(() => {
      //   this.loading = false;
      // }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/index.scss";
</style>
