<template>
  <div class="custom-header relative">
    <div class="header-bg-wpr relative">
      <div class="header-bg absolute">
        <img
          class="header-bg--img"
          src="../assets/images/title-bg.png"
          alt=""
        />
      </div>
    </div>
    <div class="header--title glowing-text">现代产业园智慧渔业平台</div>
    <div class="header-nav">
      <div class="left-menu-wpr">
        <div
          class="first left-menu-wpr--item cursor-pointer"
          :class="getPlanningActiveClass"
          @click="$router.push({ name: 'planning' })"
        >
          规划一张图
        </div>
        <div
          class="second left-menu-wpr--item cursor-pointer"
          :class="getManageActiveClass"
          @click="$router.push({ name: 'management' })"
        >
          经营一张表
        </div>
      </div>
      <div class="right-menu-wpr">
        <div
          class="third right-menu-wpr--item cursor-pointer"
          :class="getOperationActiveClass"
          @click="$router.push({ name: 'operation' })"
        >
          运营一张网
        </div>
        <!-- @click="$router.push({ name: 'production' })" -->
        <div
          class="fourth right-menu-wpr--item"
          :class="getProductionActiveClass"
        >
          生产一张表
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
export default {
  mixins: [drawMixin],
  data() {
    return {
      loading: true,
      decorationColor: ["#568aea", "#000000"],
      timing: null,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    };
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  computed: {
    getManageActiveClass() {
      return this.$route.path.slice(1) === "management" ? "nav-active" : "";
    },
    getOperationActiveClass() {
      return this.$route.path.slice(1) === "operation" ? "nav-active" : "";
    },
    getPlanningActiveClass() {
      return this.$route.path.slice(1) === "planning" ? "nav-active" : "";
    },
    getProductionActiveClass() {
      return this.$route.path.slice(1) === "production" ? "nav-active" : "";
    },
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-top: 1rem;
  color: white;
}
.react-right {
  background-color: #0f1325;
}
.nav-active {
  opacity: 1 !important;
}
.header-bg-wpr {
  width: 100%;
  height: 110px;
  overflow: hidden;
  // background: red;
}
.header {
  &--title {
    top: 42px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    font-weight: 900;
    font-size: 32px;
  }
}
.header-bg {
  width: 100%;
  height: 215px;
  &--img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.header-nav {
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 310px 0 300px;
}
.left-menu-wpr {
  display: flex;
  &--item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 200px;
    height: 52px;
    font-weight: bold;
    font-size: 24px;
    color: #e1e3ef;
    opacity: 0.5;
  }
  & .first {
    margin-right: -12px;
    background-image: url("../assets/images/left1.png");
  }
  & .second {
    background-image: url("../assets/images/left12.png");
  }
}
.right-menu-wpr {
  display: flex;
  &--item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 200px;
    height: 52px;
    font-weight: bold;
    font-size: 24px;
    color: #e1e3ef;
    opacity: 0.5;
  }
  & .third {
    background-image: url("../assets/images/right2.png");
  }
  & .fourth {
    margin-left: -12px;
    background-image: url("../assets/images/right1.png");
  }
}
</style>
